import React from 'react'
import BJELogo from '../assets/img/Logo.svg'
import SSVKroschkeLogo from '../assets/_defaultAssets/Logos/JS/SSVKroschkeLogo'

export default function NavBar() {
    return (
        <header id="page-nav">
            <nav className="navbar bg-body-transparent">
                <a className="navbar-brand" href="#">
                    <SSVKroschkeLogo height="32px" />
                </a>
            </nav>
        </header>
    )
}
