import React from 'react'

export default function Header(props) {
    const  { appointmentDate, customerFirstname, customerLastname, admFirstname, admLastname, customerSalutation, customerEmail } = props
    
    let namePhrase = <>{`${customerFirstname} ${customerLastname}`}</>

    if((customerFirstname === "") && (customerLastname !== "")) {
        namePhrase = <>{`${customerSalutation} ${customerLastname}`}</>
    } else if((customerLastname === "") && (customerFirstname !== "")) {
        namePhrase = <>{`${customerFirstname}`}</>
    } else if((customerFirstname === "") && (customerLastname === "")) {
        namePhrase = <>{`${customerEmail}`}</>
    }

    return (
        <div className="q-hero-section">
            <div className="primary-line"></div>
            <div className="container p-lg-0">
                <div className="row">
                    <div className="col-12">
                    <h2>
                        Guten Tag<br/>{customerSalutation} {customerLastname},
                    </h2>
                    </div>
                    <div className="col-8 col-md-6">
                        <h3>
                            hier ist Ihre Zusammenfassung von<br className="d-none d-lg-inline"/>unserem Gespräch am {appointmentDate}.
                        </h3>
                        <h4>
                            Viele Grüße<br/>{admFirstname} {admLastname}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
