import React from "react";

export default function NextAppointment({ date, place, href }) {
  return (
    <div className="container">
      <section className="row" id="next-appointment">
        <div className="col-1 d-none d-sm-block">
          <div className="line transparent">
            <div className="number"></div>
            <div className="dot black"></div>
          </div>
        </div>
        <div className="col-12 col-sm-11 col-lg-6 py-5">
          <div className="content">
            <h2>Nächster Termin</h2>
            <p>
              Wir sehen uns wieder am:
              <br />
              {date}
              <br />
              {place}
            </p>
            <div>
              <div>
                <a className="btn btn-primary mt-4" href={href}>Änderungswunsch</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
