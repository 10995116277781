import React, { useCallback, useEffect, useState } from "react";
import CircularIndeterminate from "./CircularLoader.js";
import Header from "./Header";
import Anchorlinks from "./Anchorlinks.js";
import Footer from "./Footer";
import NavBar from "./NavBar";
import NextAppointment from "./NextAppointment";
import dayjs from "dayjs";
import DownloadPdf from "./ExportPDF/ExportPDF";
import APIService from '../api'
import ChapterData from "./ChaperData";

import SSVKroschkeLogo from "../assets/_defaultAssets/Logos/JS/SSVKroschkeLogo";

export default function DashboardComponent({ id }) {
  const [chapters, setChapters] = useState(null);
  const [consultation, setConsultation] = useState(null);

  const checkValidId = useCallback(() => {
    let cId = null;

    const regex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm;
    let m;

    while ((m = regex.exec(id)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      //eslint-disable-next-line no-loop-func
      m.forEach((match, groupIndex) => {
        cId = match;
      });
    }

    return cId === id;
  }, [id]);

  const getChapters = useCallback(async () => {
    try {
      const data = await APIService.get(
        '/api/state',
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      )
      setChapters(data.modules);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getConsultation = useCallback(async () => {
    try {
      const data = await APIService.get(
        `/api/consultation/${id}`,
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      )
      data.consulting.next_appointment =
        data.consulting.next_appointment === "0001-01-01T00:00:00Z"
          ? null
          : data.consulting.next_appointment;
      setConsultation(data);
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  const updateConsultationHit = useCallback(async () => {
    try {
      const data = await APIService.get(
        `/api/consultation/${id}/addhit`,
        (status, data) => {
          if (status === 200) {
            return data;
          }
        }
      )
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  
  useEffect(() => {
    if (checkValidId()) {
      getConsultation();
      getChapters();
      updateConsultationHit();
    } else {
      console.error("PROVIDE ID!");
    }
  }, [getConsultation, getChapters, checkValidId]);

  if (!consultation || !chapters) {
    return (
      <div
        style={{ alignSelf: "center", margin: "0 auto" }}
        className="loadingScreen"
      >
        <div className="loader d-flex justify-content-center align-items-center">
          <CircularIndeterminate />
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5 loader-text">
          <SSVKroschkeLogo height="40px" />
          <p className="mb-0 ml-3">eReport</p>
        </div>
      </div>
    );
  }

  const { consulting, consultant, customer, fieldValues } = consultation;
  const { company } = consultant;

  const options = { weekday: "long", month: "long", day: "numeric" };
  const appointmentDate = dayjs(consulting.appointment).format(
    "dddd, DD. MMMM"
  );

  const chaptersWithFields = [];
  const activeChapters = JSON.parse(consultation.consulting.chapters).map(
    (value) => {
      return chapters.filter((module) => {
        return module?.chapter?.key === value;
      })[0]?.chapter;
    }
  );

  console.log(process.env);

  return (
    <div key="dashboard">
      <NavBar />
      <main id="q-page-content">
        <Header
          appointmentDate={appointmentDate}
          customerFirstname={customer.firstname}
          customerLastname={customer.lastname}
          customerSalutation={customer.salutation}
          customerEmail={customer.email}
          admSalutation={consultant.salutation}
          admFirstname={consultant.firstname}
          admLastname={consultant.lastname}
        />
        <Anchorlinks
          chapters={activeChapters}
          notes={consulting.notes !== ""}
          nextAppointment={consulting.next_appointment}
        />
        <div className="container" id="wrapper">
          {activeChapters.map((module, i) => {
            const newID = i + 1;
            return (
              <ChapterData
                key={module.key}
                chapter={module}
                fields={[]}
                fieldValues={fieldValues}
                id={newID}
              />
            );
          })}
        </div>
        <div id="ansprechpartner">
          <div className="bg-effekt">
            <div className="container">
              <section className="row" id="contact">
                <div className="col-1 d-none d-sm-block">
                  <div className="line transparent">
                    <div className="number"></div>
                    <div className="dot black"></div>
                  </div>
                </div>
                <div className="col-12 col-sm-11 col-lg-6 py-5">
                  <div className="content">
                    <h2>Kontaktdaten</h2>
                    <p>
                      <strong>
                        {consultant.salutation} {consultant.firstname}{" "}
                        {consultant.lastname}
                      </strong>
                      <br />
                      {consultant.company.name}
                      <br />
                      {consultant.company.street}
                      <br />
                      {consultant.company.postcode} {consultant.company.city}
                    </p>
                    {(consultant.email || consultant.telefon) && (
                      <p>
                        {consultant.email && (
                          <>
                            E-Mail: {consultant.email}
                            <br />
                          </>
                        )}
                        {consultant.telefon && (
                          <>Telefon: {consultant.telefon}</>
                        )}
                      </p>
                    )}

                    <div>
                      <div>
                        <a
                          className="btn btn-primary mt-4"
                          href={`mailto:${consultant.email}?subject=SSV-Kroschke%20eReport:%20Kontaktaufnahme`}
                        >
                          Kontakt aufnehmen
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {dayjs(consulting.next_appointment).isValid() && (
          <NextAppointment
            date={dayjs(consulting.next_appointment).format("dddd, DD. MMMM")}
            place={consulting.next_appointment_place}
            href={`mailto:${consultant.email}?subject=SSV-Kroschke%20eReport:%20Terminänderung`}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}
