import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/js/bootstrap";
import "./assets/sass/bje/style.sass";
import "./assets/sass/html5up/main.scss";
import "./index.css";
import "react-activity/dist/react-activity.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
