import React from 'react'

export default function SSVKroschkeLogo(props) {
  return (
    <svg {...props} viewBox="0 0 450 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_44_354)">
        <path d="M102.2 56.59H42.5C42.3674 56.59 42.26 56.6974 42.26 56.83V99.46C42.26 99.5925 42.3674 99.7 42.5 99.7H102.2C102.333 99.7 102.44 99.5925 102.44 99.46V56.83C102.44 56.6974 102.333 56.59 102.2 56.59Z" fill="#E20031"/>
        <path d="M100.67 58.34V97.9699H44.01V58.34H100.67ZM102.2 54.8299H42.48C41.9496 54.8299 41.4409 55.0407 41.0658 55.4157C40.6907 55.7908 40.48 56.2995 40.48 56.8299V99.4699C40.48 100 40.6907 100.509 41.0658 100.884C41.4409 101.259 41.9496 101.47 42.48 101.47H102.19C102.72 101.47 103.229 101.259 103.604 100.884C103.979 100.509 104.19 100 104.19 99.4699V56.8199C104.19 56.2895 103.979 55.7808 103.604 55.4057C103.229 55.0307 102.72 54.8199 102.19 54.8199L102.2 54.8299Z" fill="#E20031"/>
        <path d="M29.62 1.76001H2.08C1.90327 1.76001 1.76 1.90328 1.76 2.08001V99.38C1.76 99.5567 1.90327 99.7 2.08 99.7H29.62C29.7967 99.7 29.94 99.5567 29.94 99.38V2.08001C29.94 1.90328 29.7967 1.76001 29.62 1.76001Z" fill="#4B4B4D"/>
        <path d="M28.17 3.50997V97.97H3.48V3.50997H28.17ZM29.61 -0.0300293H2.07C1.521 -0.0300293 0.994489 0.188059 0.606289 0.57626C0.218089 0.96446 0 1.49097 0 2.03997V99.35C0 99.899 0.218089 100.425 0.606289 100.814C0.994489 101.202 1.521 101.42 2.07 101.42H29.61C30.1599 101.42 30.6875 101.202 31.0773 100.814C31.467 100.426 31.6874 99.8999 31.69 99.35V2.06997C31.6926 1.79514 31.6408 1.52252 31.5374 1.26785C31.4341 1.01317 31.2813 0.781512 31.0879 0.586243C30.8945 0.390973 30.6643 0.23597 30.4106 0.130192C30.157 0.0244136 29.8848 -0.030042 29.61 -0.0300293Z" fill="#E20031"/>
        <path d="M160.6 1.76001H116.25C115.792 1.76001 115.42 2.13161 115.42 2.59001V98.87C115.42 99.3284 115.792 99.7 116.25 99.7H160.6C161.058 99.7 161.43 99.3284 161.43 98.87V2.59001C161.43 2.13161 161.058 1.76001 160.6 1.76001Z" fill="#E20031"/>
        <path d="M159.67 3.50997V97.97H117.18V3.50997H159.67ZM160.6 -2.86102e-05H116.25C115.564 0.0026052 114.907 0.276325 114.422 0.761475C113.936 1.24662 113.663 1.90387 113.66 2.58997V98.86C113.663 99.5461 113.936 100.203 114.422 100.688C114.907 101.174 115.564 101.447 116.25 101.45H160.6C161.286 101.447 161.943 101.174 162.429 100.688C162.914 100.203 163.187 99.5461 163.19 98.86V2.58997C163.193 2.2477 163.128 1.90827 162.999 1.59116C162.87 1.27405 162.68 0.985513 162.439 0.74209C162.198 0.498668 161.912 0.305155 161.597 0.172655C161.281 0.0401554 160.942 -0.028723 160.6 -0.0300293V-2.86102e-05Z" fill="#E20031"/>
        <path d="M180.68 47.6799H201.98C202.09 55.0699 205.06 55.6799 211.2 55.6799H237.32C241.84 55.6799 246.44 55.1999 246.44 49.8299C246.44 43.8299 243.94 43.3899 216.44 43.3899C184.38 43.3899 180.73 39.5499 180.73 22.4599C180.73 9.88994 181.89 0.189941 208.73 0.189941H238.49C263.07 0.189941 265.37 8.54994 265.37 20.7299V22.9499H244.13C244.13 17.8599 243.56 15.7499 233.77 15.7499H210.15C205.35 15.7499 202.09 17.3799 202.09 21.1299C202.09 27.1299 204.68 27.3599 227.53 27.2699C260.65 27.1699 268.33 29.1899 268.33 49.4399C268.33 68.1699 261.99 71.2299 233.67 71.2299H207.85C188.45 71.2299 180.68 67.5899 180.68 52.5099V47.6799Z" fill="#E20031"/>
        <path d="M275.53 47.72H296.84C296.93 55.11 299.91 55.72 306.06 55.72H332.16C336.68 55.72 341.28 55.24 341.28 49.87C341.28 43.87 338.79 43.43 311.34 43.43C279.28 43.43 275.62 39.59 275.62 22.51C275.62 9.92998 276.78 0.22998 303.62 0.22998H333.39C357.96 0.22998 360.27 8.58998 360.27 20.77V22.97H338.98C338.98 17.88 338.4 15.77 328.61 15.77H305C300.2 15.77 296.93 17.4 296.93 21.15C296.93 27.15 299.53 27.38 322.37 27.29C355.49 27.19 363.18 29.21 363.18 49.46C363.18 68.19 356.84 71.25 328.52 71.25H302.7C283.31 71.25 275.53 67.62 275.53 52.53V47.72Z" fill="#E20031"/>
        <path d="M370.03 0.929932H390.33V50.0099L424.16 0.929932H449.31L397.36 70.4299H370.03V0.929932Z" fill="#E20031"/>
        <path d="M191.48 101.37L186.38 91.24L183.09 95.67V101.37H180.5V81.61H183.09V89.18C183.09 89.5 183.09 89.83 183.09 90.18C183.09 90.53 183.09 90.86 183.09 91.18C183.09 91.5 183.09 91.74 183.09 91.89C183.191 91.6905 183.308 91.4999 183.44 91.32L184 90.52C184.2 90.23 184.4 89.94 184.61 89.66C184.82 89.38 184.99 89.13 185.14 88.92L190.39 81.61H193.39L188.2 88.78L194.48 101.37H191.48Z" fill="#4B4B4D"/>
        <path d="M197.64 101.37V82.31L200.28 80.79V101.37H197.64Z" fill="#4B4B4D"/>
        <path d="M211.86 101.37V99.91C211.395 100.421 210.847 100.851 210.24 101.18C209.626 101.511 208.938 101.683 208.24 101.68C207.687 101.676 207.137 101.588 206.61 101.42C206.059 101.239 205.552 100.946 205.12 100.56C204.657 100.122 204.292 99.59 204.05 99C203.752 98.2672 203.609 97.4807 203.63 96.69C203.61 95.9532 203.746 95.2204 204.03 94.54C204.286 93.9625 204.669 93.4499 205.15 93.04C205.642 92.6394 206.21 92.3434 206.82 92.17C207.506 91.9759 208.217 91.8816 208.93 91.89C209.96 91.8416 210.987 92.0333 211.93 92.45V91.35C211.97 91.0107 211.933 90.6667 211.821 90.3439C211.709 90.0211 211.525 89.7279 211.284 89.4863C211.042 89.2447 210.749 89.061 210.426 88.9491C210.103 88.8372 209.759 88.7999 209.42 88.84C208.793 88.8307 208.168 88.9046 207.56 89.06C206.992 89.2188 206.45 89.4579 205.95 89.77L204.95 87.57C205.577 87.1318 206.269 86.7944 207 86.57C207.814 86.3294 208.661 86.2148 209.51 86.23C210.825 86.17 212.115 86.6094 213.12 87.46C213.607 87.9462 213.983 88.5325 214.221 89.1783C214.46 89.824 214.555 90.5139 214.5 91.2V101.37H211.86ZM211.86 95.05C211.471 94.8256 211.049 94.6636 210.61 94.57C210.021 94.4477 209.421 94.3907 208.82 94.4C208.105 94.3623 207.4 94.5705 206.82 94.99C206.581 95.1835 206.39 95.4294 206.262 95.7086C206.134 95.9878 206.071 96.2929 206.08 96.6C206.064 96.9243 206.112 97.2485 206.222 97.554C206.332 97.8595 206.501 98.1402 206.72 98.38C206.975 98.6213 207.277 98.8064 207.608 98.9235C207.939 99.0407 208.29 99.0872 208.64 99.06C209.325 99.0657 209.996 98.8596 210.56 98.47C211.072 98.1234 211.513 97.6821 211.86 97.17V95.05Z" fill="#4B4B4D"/>
        <path d="M226.16 101.37V99.97C225.821 100.524 225.332 100.971 224.75 101.26C224.148 101.561 223.483 101.715 222.81 101.71C222.13 101.751 221.45 101.621 220.833 101.331C220.216 101.04 219.682 100.6 219.28 100.05C218.364 98.5507 217.941 96.8022 218.07 95.05V86.55H220.71V94.97C220.607 96.07 220.86 97.1739 221.43 98.12C221.669 98.417 221.975 98.6532 222.322 98.8096C222.67 98.966 223.049 99.0381 223.43 99.0201C223.821 99.0354 224.209 98.9517 224.558 98.7769C224.908 98.6021 225.208 98.3417 225.43 98.0201C225.95 97.1859 226.198 96.2111 226.14 95.23V86.58H228.78V101.37H226.16Z" fill="#4B4B4D"/>
        <path d="M241.48 99.38C241.223 99.9122 240.843 100.375 240.37 100.73C239.896 101.074 239.359 101.322 238.79 101.46C238.171 101.611 237.537 101.684 236.9 101.68C235.968 101.679 235.044 101.499 234.18 101.15C233.324 100.823 232.524 100.364 231.81 99.79L233 97.59C233.583 98.0596 234.238 98.4312 234.94 98.69C235.559 98.9303 236.216 99.0557 236.88 99.06C238.5 99.06 239.31 98.51 239.31 97.42C239.309 97.1417 239.232 96.8689 239.088 96.631C238.943 96.3932 238.737 96.1992 238.49 96.07C237.761 95.6394 237.003 95.2617 236.22 94.94C235.591 94.6803 234.982 94.3727 234.4 94.02C233.952 93.7445 233.545 93.408 233.19 93.02C232.884 92.6724 232.655 92.2632 232.52 91.82C232.373 91.3202 232.302 90.801 232.31 90.28C232.298 89.6802 232.41 89.0843 232.64 88.53C232.85 88.0393 233.172 87.6045 233.58 87.26C234.007 86.9085 234.5 86.6466 235.03 86.49C235.621 86.3162 236.234 86.2319 236.85 86.24C237.687 86.2384 238.518 86.3909 239.3 86.69C240.047 86.9716 240.743 87.3736 241.36 87.88L240.22 90.11C239.731 89.6899 239.177 89.3522 238.58 89.11C238 88.898 237.387 88.793 236.77 88.8C236.276 88.7496 235.78 88.8851 235.38 89.18C235.236 89.3026 235.123 89.4565 235.048 89.6299C234.974 89.8033 234.94 89.9915 234.95 90.18C234.946 90.3617 234.981 90.5421 235.05 90.71C235.146 90.8952 235.275 91.0612 235.43 91.2C235.662 91.3979 235.913 91.5721 236.18 91.72C236.49 91.9 236.9 92.1 237.41 92.33C238.15 92.65 238.8 92.96 239.41 93.25C239.927 93.5121 240.402 93.8491 240.82 94.25C241.196 94.6076 241.486 95.0451 241.67 95.53C241.867 96.1023 241.962 96.7049 241.95 97.31C241.959 98.0275 241.798 98.7369 241.48 99.38Z" fill="#4B4B4D"/>
        <path d="M263.48 101.37L258.39 91.24L255.09 95.67V101.37H252.48V81.61H255.07V89.18C255.07 89.5 255.07 89.83 255.07 90.18C255.07 90.53 255.07 90.86 255.07 91.18C255.07 91.5 255.07 91.74 255.07 91.89C255.14 91.75 255.26 91.57 255.42 91.32L255.98 90.52L256.59 89.66C256.79 89.37 256.97 89.13 257.12 88.92L262.37 81.61H265.37L260.17 88.78L266.48 101.37H263.48Z" fill="#4B4B4D"/>
        <path d="M276.73 89.6301C276.449 89.4643 276.151 89.3302 275.84 89.2301C275.478 89.1317 275.105 89.0845 274.73 89.0901C274.35 89.0792 273.973 89.1653 273.636 89.3403C273.298 89.5153 273.01 89.7733 272.8 90.0901C272.283 90.9466 272.042 91.9418 272.11 92.9401V101.41H269.48V86.5501H272.11V87.9701C272.431 87.4694 272.862 87.0487 273.37 86.7401C273.908 86.4099 274.529 86.2399 275.16 86.2501C275.608 86.2393 276.056 86.2897 276.49 86.4001C276.822 86.5024 277.133 86.6614 277.41 86.8701L276.73 89.6301Z" fill="#4B4B4D"/>
        <path d="M290.21 97.18C289.95 98.0726 289.522 98.9074 288.95 99.64C288.437 100.281 287.783 100.794 287.039 101.141C286.295 101.488 285.481 101.659 284.66 101.64C283.851 101.648 283.053 101.462 282.33 101.1C281.611 100.729 280.98 100.207 280.48 99.57C279.933 98.837 279.517 98.0149 279.25 97.14C278.947 96.1145 278.798 95.0494 278.81 93.98C278.795 92.8801 278.946 91.7843 279.26 90.73C279.53 89.8477 279.957 89.0213 280.52 88.29C281.021 87.6465 281.66 87.1237 282.39 86.76C283.123 86.4084 283.927 86.2305 284.74 86.24C285.559 86.2318 286.369 86.4205 287.1 86.79C287.83 87.1666 288.474 87.6911 288.99 88.33C289.55 89.049 289.971 89.8664 290.23 90.74C290.83 92.8448 290.83 95.0752 290.23 97.18H290.21ZM287.13 90.35C286.869 89.9279 286.501 89.5819 286.064 89.3467C285.627 89.1115 285.136 88.9954 284.64 89.01C284.164 89.0006 283.694 89.1202 283.28 89.3561C282.867 89.592 282.524 89.9354 282.29 90.35C281.683 91.4276 281.392 92.6545 281.45 93.89C281.362 95.2006 281.679 96.5065 282.36 97.63C282.618 98.0309 282.973 98.3597 283.393 98.5853C283.813 98.811 284.283 98.9262 284.76 98.92C285.24 98.9259 285.711 98.7864 286.11 98.52C286.514 98.2405 286.855 97.8794 287.11 97.46C287.413 96.9789 287.635 96.4521 287.77 95.9C287.924 95.2716 288.001 94.6269 288 93.98C288.081 92.7101 287.778 91.445 287.13 90.35Z" fill="#4B4B4D"/>
        <path d="M302.48 99.38C302.223 99.9095 301.847 100.372 301.38 100.73C300.901 101.072 300.362 101.32 299.79 101.46C299.175 101.61 298.543 101.684 297.91 101.68C296.974 101.68 296.048 101.5 295.18 101.15C294.326 100.818 293.527 100.36 292.81 99.79L294 97.59C294.584 98.058 295.239 98.4294 295.94 98.69C296.559 98.9294 297.216 99.0547 297.88 99.06C299.5 99.06 300.31 98.51 300.31 97.42C300.309 97.1417 300.232 96.8689 300.088 96.631C299.943 96.3931 299.737 96.1991 299.49 96.07C298.761 95.6393 298.003 95.2617 297.22 94.94C296.594 94.6804 295.989 94.3728 295.41 94.02C294.957 93.7471 294.546 93.4103 294.19 93.02C293.884 92.6723 293.655 92.2632 293.52 91.82C293.376 91.3196 293.309 90.8005 293.32 90.28C293.308 89.6801 293.42 89.0842 293.65 88.53C293.857 88.0374 294.179 87.6018 294.59 87.26C295.012 86.907 295.502 86.6449 296.03 86.49C296.621 86.3176 297.234 86.2334 297.85 86.24C298.687 86.2374 299.518 86.39 300.3 86.69C301.051 86.9692 301.751 87.3713 302.37 87.88L301.23 90.11C300.739 89.6876 300.182 89.3497 299.58 89.11C299.004 88.8988 298.394 88.7937 297.78 88.8C297.286 88.7495 296.79 88.885 296.39 89.18C296.245 89.302 296.13 89.4556 296.054 89.6289C295.978 89.8023 295.942 89.9908 295.95 90.18C295.95 90.3624 295.987 90.5428 296.06 90.71C296.149 90.8993 296.279 91.0666 296.44 91.2C296.667 91.4003 296.915 91.5747 297.18 91.72C297.5 91.9 297.91 92.1 298.41 92.33C299.16 92.65 299.81 92.96 300.41 93.25C300.922 93.5143 301.394 93.8511 301.81 94.25C302.186 94.6076 302.476 95.045 302.66 95.53C302.862 96.1011 302.957 96.7045 302.94 97.31C302.952 98.0266 302.795 98.736 302.48 99.38Z" fill="#4B4B4D"/>
        <path d="M314.4 90.81C313.952 90.2691 313.414 89.8091 312.81 89.45C312.307 89.1549 311.733 89.0027 311.15 89.01C310.688 89.005 310.232 89.1238 309.831 89.3542C309.43 89.5845 309.099 89.918 308.87 90.32C308.258 91.4174 307.971 92.6657 308.04 93.92C308.033 94.592 308.1 95.2628 308.24 95.92C308.352 96.4776 308.558 97.0119 308.85 97.5C309.099 97.9083 309.442 98.2512 309.85 98.5C310.265 98.7524 310.744 98.8807 311.23 98.87C311.793 98.886 312.347 98.7213 312.81 98.4C313.34 97.9992 313.823 97.5396 314.25 97.03L315.92 98.87C315.665 99.2428 315.385 99.597 315.08 99.93C314.784 100.261 314.448 100.553 314.08 100.8C313.683 101.067 313.248 101.272 312.79 101.41C312.264 101.566 311.718 101.64 311.17 101.63C310.381 101.634 309.603 101.449 308.9 101.09C308.186 100.71 307.565 100.177 307.08 99.53C306.534 98.805 306.121 97.9891 305.86 97.12C305.556 96.0913 305.408 95.0227 305.42 93.95C305.402 92.8427 305.557 91.7394 305.88 90.68C306.14 89.7973 306.56 88.9701 307.12 88.24C307.611 87.595 308.244 87.0715 308.97 86.71C309.679 86.3618 310.46 86.1837 311.25 86.19C312.163 86.1739 313.064 86.3908 313.87 86.82C314.692 87.2916 315.404 87.9324 315.96 88.7L314.4 90.81Z" fill="#4B4B4D"/>
        <path d="M327.35 101.37V92.97C327.453 91.8669 327.201 90.7595 326.63 89.81C326.391 89.5131 326.085 89.2769 325.738 89.1205C325.39 88.9641 325.011 88.892 324.63 88.91C324.24 88.8957 323.852 88.9797 323.502 89.1544C323.153 89.3292 322.853 89.589 322.63 89.91C322.109 90.7401 321.861 91.712 321.92 92.69V101.34H319.28V82.31L321.92 80.79V87.97C322.26 87.4188 322.749 86.9751 323.33 86.69C323.93 86.381 324.596 86.223 325.27 86.23C325.951 86.1911 326.631 86.3235 327.248 86.6153C327.864 86.907 328.398 87.3487 328.8 87.9C329.712 89.401 330.135 91.1482 330.01 92.9V101.39L327.35 101.37Z" fill="#4B4B4D"/>
        <path d="M341.32 101.37L338.38 93.97L336.38 96.97V101.34H333.74V82.31L336.38 80.79V90.97C336.38 91.25 336.38 91.54 336.38 91.83C336.38 92.12 336.38 92.4 336.38 92.66V93.34C336.57 93.04 336.81 92.65 337.09 92.17L337.85 90.91L340.62 86.59H343.62L340.25 91.59L344.25 101.44L341.32 101.37Z" fill="#4B4B4D"/>
        <path d="M357.26 94.4101C357.26 94.6501 357.26 94.8401 357.26 94.9901H348.46C348.511 95.6193 348.649 96.2385 348.87 96.8301C349.047 97.2917 349.304 97.7183 349.63 98.0901C349.909 98.4003 350.25 98.6489 350.63 98.8201C350.996 98.9799 351.391 99.0616 351.79 99.0601C352.316 99.0686 352.838 98.9628 353.32 98.7501C353.814 98.5252 354.272 98.2287 354.68 97.8701L356.23 99.7401C355.659 100.34 354.981 100.829 354.23 101.18C353.407 101.535 352.516 101.706 351.62 101.68C350.851 101.686 350.092 101.515 349.4 101.18C348.682 100.824 348.053 100.312 347.56 99.6801C347.002 98.9526 346.582 98.129 346.32 97.2501C346.002 96.1896 345.847 95.0871 345.86 93.9801C345.844 92.8513 345.992 91.7262 346.3 90.6401C346.539 89.7679 346.943 88.9498 347.49 88.2301C347.974 87.5982 348.601 87.0908 349.32 86.7501C350.062 86.4053 350.872 86.231 351.69 86.2401C352.554 86.2214 353.407 86.4246 354.17 86.8301C354.868 87.2267 355.467 87.7773 355.92 88.4401C356.391 89.1645 356.73 89.967 356.92 90.8101C357.155 91.7452 357.273 92.7059 357.27 93.6701C357.29 93.9701 357.28 94.1801 357.26 94.4101ZM354.41 91.1501C354.294 90.7252 354.101 90.3252 353.84 89.9701C353.589 89.6311 353.264 89.354 352.89 89.1601C352.46 88.9525 351.987 88.853 351.51 88.8701C351.107 88.8566 350.707 88.9404 350.343 89.1145C349.979 89.2886 349.662 89.5477 349.42 89.8701C348.829 90.6716 348.482 91.6264 348.42 92.6201H354.61C354.598 92.1242 354.531 91.6311 354.41 91.1501Z" fill="#4B4B4D"/>
        <path d="M380.67 97.04C380.376 97.966 379.904 98.8255 379.28 99.57C378.691 100.251 377.956 100.791 377.13 101.15C376.273 101.513 375.35 101.694 374.42 101.68C373.341 101.7 372.274 101.445 371.32 100.94C370.38 100.43 369.572 99.7072 368.96 98.83C368.274 97.8522 367.763 96.7627 367.45 95.61C366.743 92.9122 366.743 90.0778 367.45 87.38C367.763 86.2242 368.274 85.1314 368.96 84.15C369.572 83.2757 370.38 82.5566 371.32 82.05C372.273 81.5416 373.34 81.2835 374.42 81.3C375.702 81.2536 376.97 81.5801 378.07 82.24C379.106 82.9147 379.939 83.8584 380.48 84.97L378.15 86.58C377.792 85.8771 377.275 85.2675 376.64 84.8C375.986 84.3896 375.221 84.1906 374.45 84.23C373.737 84.2078 373.03 84.377 372.404 84.7199C371.778 85.0628 371.255 85.5669 370.89 86.18C370.081 87.8225 369.66 89.629 369.66 91.46C369.66 93.291 370.081 95.0974 370.89 96.74C371.249 97.3624 371.769 97.8765 372.396 98.2285C373.022 98.5804 373.732 98.7571 374.45 98.74C375.087 98.7603 375.72 98.6337 376.3 98.37C376.786 98.1383 377.211 97.7958 377.54 97.37C377.87 96.9532 378.112 96.4734 378.25 95.96C378.407 95.414 378.485 94.8482 378.48 94.28V94.19H375.59V91.34H381.2V93.63C381.209 94.7878 381.03 95.9394 380.67 97.04Z" fill="#4B4B4D"/>
        <path d="M391.81 89.6301C391.529 89.4643 391.231 89.3302 390.92 89.2301C390.559 89.1303 390.185 89.0832 389.81 89.0901C389.43 89.0792 389.053 89.1653 388.716 89.3403C388.378 89.5153 388.09 89.7733 387.88 90.0901C387.367 90.948 387.129 91.9429 387.2 92.9401V101.41H384.56V86.5501H387.2V87.9701C387.514 87.4675 387.942 87.0461 388.45 86.7401C388.988 86.4099 389.609 86.2399 390.24 86.2501C390.688 86.2393 391.136 86.2897 391.57 86.4001C391.902 86.5024 392.213 86.6614 392.49 86.8701L391.81 89.6301Z" fill="#4B4B4D"/>
        <path d="M403.14 101.37V99.97C402.802 100.521 402.317 100.968 401.74 101.26C401.138 101.56 400.473 101.714 399.8 101.71C399.118 101.752 398.437 101.622 397.818 101.332C397.2 101.042 396.664 100.601 396.26 100.05C395.353 98.5475 394.934 96.8007 395.06 95.05V86.55H397.69V94.97C397.587 96.07 397.84 97.1739 398.41 98.12C398.649 98.4163 398.955 98.6522 399.303 98.8085C399.65 98.9648 400.029 99.0373 400.41 99.0201C400.8 99.0344 401.188 98.9504 401.538 98.7756C401.887 98.6009 402.187 98.3411 402.41 98.0201C402.925 97.1845 403.17 96.21 403.11 95.23V86.58H405.75V101.37H403.14Z" fill="#4B4B4D"/>
        <path d="M420.35 97.52C420.112 98.3604 419.722 99.1501 419.2 99.85C418.776 100.421 418.224 100.884 417.588 101.202C416.953 101.52 416.251 101.684 415.54 101.68C414.872 101.683 414.216 101.507 413.64 101.17C413.087 100.863 412.595 100.456 412.19 99.97V105.32L409.61 106.82V86.55H412.19V87.97C412.658 87.4914 413.186 87.0747 413.76 86.73C414.335 86.3904 414.993 86.2173 415.66 86.23C416.352 86.2284 417.035 86.3821 417.66 86.68C418.313 87.0055 418.874 87.4904 419.29 88.09C419.805 88.8376 420.175 89.6755 420.38 90.56C420.664 91.7487 420.798 92.9681 420.78 94.19C420.805 95.3153 420.66 96.4379 420.35 97.52ZM417.35 90.21C417.133 89.8171 416.812 89.4915 416.422 89.2692C416.032 89.0469 415.589 88.9365 415.14 88.95C414.519 88.9493 413.914 89.1531 413.42 89.53C412.921 89.8855 412.488 90.3254 412.14 90.83V97.04C412.302 97.2801 412.486 97.5044 412.69 97.71C412.912 97.9401 413.157 98.1478 413.42 98.33C413.688 98.5163 413.976 98.6707 414.28 98.79C414.598 98.9162 414.938 98.9774 415.28 98.97C415.71 98.989 416.137 98.8912 416.515 98.6869C416.894 98.4827 417.21 98.1796 417.43 97.81C417.989 96.7215 418.242 95.5011 418.16 94.28C418.253 92.8789 417.985 91.4774 417.38 90.21H417.35Z" fill="#4B4B4D"/>
        <path d="M434.8 97.5201C434.562 98.3605 434.172 99.1502 433.65 99.8501C433.226 100.421 432.674 100.884 432.038 101.202C431.403 101.52 430.701 101.684 429.99 101.68C429.322 101.683 428.666 101.507 428.09 101.17C427.535 100.865 427.043 100.458 426.64 99.9701V105.32L424.05 106.82V86.5501H426.64V87.9701C427.106 87.4892 427.634 87.0722 428.21 86.7301C428.784 86.389 429.442 86.2158 430.11 86.2301C430.802 86.2256 431.486 86.3795 432.11 86.6801C432.768 87.0023 433.333 87.4877 433.75 88.0901C434.261 88.8399 434.631 89.6771 434.84 90.5601C435.12 91.7492 435.251 92.9686 435.23 94.1901C435.254 95.3153 435.109 96.4379 434.8 97.5201ZM431.8 90.2101C431.582 89.8178 431.261 89.4929 430.871 89.2707C430.482 89.0486 430.038 88.9376 429.59 88.9501C428.968 88.9478 428.363 89.1518 427.87 89.5301C427.37 89.888 426.934 90.3274 426.58 90.8301V97.0401C426.749 97.2778 426.936 97.5019 427.14 97.7101C427.362 97.9402 427.607 98.1478 427.87 98.3301C428.138 98.5164 428.426 98.6707 428.73 98.7901C429.048 98.9163 429.388 98.9775 429.73 98.9701C430.159 98.9905 430.585 98.8932 430.962 98.6887C431.339 98.4843 431.653 98.1804 431.87 97.8101C432.433 96.7226 432.689 95.5021 432.61 94.2801C432.703 92.879 432.435 91.4774 431.83 90.2101H431.8Z" fill="#4B4B4D"/>
        <path d="M449.28 94.41C449.28 94.65 449.28 94.84 449.23 94.99H440.48C440.528 95.6188 440.663 96.2379 440.88 96.83C441.06 97.2922 441.321 97.7188 441.65 98.09C441.93 98.3989 442.27 98.6474 442.65 98.82C443.019 98.9798 443.418 99.0615 443.82 99.06C444.346 99.0667 444.868 98.961 445.35 98.75C445.843 98.5227 446.301 98.2264 446.71 97.87L448.25 99.74C447.681 100.343 447.002 100.831 446.25 101.18C445.428 101.537 444.536 101.708 443.64 101.68C442.875 101.685 442.119 101.514 441.43 101.18C440.709 100.83 440.079 100.316 439.59 99.68C439.028 98.9549 438.607 98.1306 438.35 97.25C438.027 96.1906 437.872 95.0873 437.89 93.98C437.871 92.8517 438.016 91.7267 438.32 90.64C438.563 89.7694 438.966 88.952 439.51 88.23C439.998 87.5982 440.628 87.091 441.35 86.75C442.092 86.4052 442.902 86.2309 443.72 86.24C444.58 86.2208 445.431 86.4241 446.19 86.83C446.888 87.2266 447.487 87.7773 447.94 88.44C448.407 89.1667 448.745 89.9684 448.94 90.81C449.172 91.7455 449.286 92.7063 449.28 93.67C449.3 93.97 449.29 94.18 449.28 94.41ZM446.42 91.15C446.312 90.722 446.118 90.3204 445.85 89.97C445.599 89.631 445.274 89.3539 444.9 89.16C444.47 88.9524 443.997 88.8529 443.52 88.87C443.117 88.8582 442.717 88.9428 442.354 89.1167C441.99 89.2907 441.674 89.5489 441.43 89.87C440.857 90.6771 440.527 91.6314 440.48 92.62H446.67C446.637 92.1228 446.553 91.6303 446.42 91.15Z" fill="#4B4B4D"/>
      </g>
      <defs>
        <clipPath id="clip0_44_354">
         <rect width="449.31" height="106.83" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}
