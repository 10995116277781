import dayjs from "dayjs";
import React from "react";

export default function Anchorlinks({ chapters, nextAppointment }) {
  return (
    <>
      <div className="bg-white sticky-top">
        <div className="container-lg py-3 py-lg-5 px-lg-0">
          <h6 className="d-none d-lg-block">Über diese Themen haben wir gesprochen:</h6>

          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div className="headline-button-container">
              <h6 class="d-lg-none mb-0">Über diese Themen haben wir gesprochen:</h6>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto flex-lg-wrap">
                {chapters.map((chapter, i) => {
                  return (
                    <li key={chapter.key} className="nav-item">
                      <a className="nav-link" href={`#${chapter.key}`}>
                        » {chapter.name}
                      </a>
                    </li>
                  );
                })}
                {dayjs(nextAppointment).isValid() && (
                  <li className="nav-item">
                    <a className="nav-link" href="#next-appointment">
                      » Nächster Termin
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
