import React from 'react'

export default function Footer() {
    return (
        <div id="footer">
            <footer className="page-footer pt-5">
                <div className="footer-meta pt-4 pb-4 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-9 d-inline-block links">
                                <span className='color-main'>© SSV-Kroschke 2023</span>
                                <a className="btn btn-link" target="_blank" href="https://www.ssv-kroschke.de/impressum">Impressum</a>
                                <a className="btn btn-link" target="_blank" href="https://www.ssv-kroschke.de/datenschutz">Datenschutz</a>
                                <a className="btn btn-link" target="_blank" href="https://www.ssv-kroschke.de/agb">AGB</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
