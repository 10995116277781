/* eslint-disable no-console */
import axios from "axios";
import Cookies from "js-cookie";

class APIService {
  constructor() {
    let service = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 10000,
    });
    
    this.service = service;

    this.isRefreshing = false;
  }

  redirectTo = (document, path) => {
    document.location = path;
  };

  async get(path, callback = () => {}) {
    return this.service
      .get(path)
      .then((response) => callback(response.status, response.data));
  }

  async delete(path, callback = () => {}) {
    return this.service
      .delete(path)
      .then((response) => callback(response.status, response.data));
  }

  async patch(path, payload, callback = () => {}) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }

  async post(path, payload, responseType, callback = () => {}) {
    const response = await this.service.request({
      method: "POST",
      url: path,
      responseType,
      data: payload,
    });
    return callback(response.status, response.data);
  }
}

export default new APIService()