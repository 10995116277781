import React from "react";
import { imgaes, placeholderImage } from "../static/data";

import { eReportData } from "../static/data";

export default function ChapterData({ chapter, fields, fieldValues, id }) {
  const content = eReportData[chapter.id];
  return (
    <section id={chapter.key}>
      <div className="row equal">
        <div className="col-1 px-sm-0 px-lg-3 d-none d-sm-block">
          <div className="line">
            <div className="number">{id < 10 ? "0" + id : id.toString()}</div>
            <div className="dot"></div>
          </div>
        </div>
        <div className="col-12 col-sm-11 py-5">
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4">{chapter.name}</h3>
            </div>
            <div className="col-12 col-md-6">
              <img
                loading="lazy"
                className="chapter-preview"
                src={imgaes[chapter.id] || placeholderImage}
                alt={imgaes[chapter.name]}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="content mt-3">
                <h4>{content.headline}</h4>
                <p className="mb-4">{content.description}</p>

                <div class="accordion mb-4" id="accordionExample">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapseChapter-${chapter.id}`}
                      aria-expanded="true"
                      aria-controls={`collapseChapter-${chapter.id}`}
                    >
                      weiterführende Links
                    </button>
                  </h2>

                  <div
                    id={`collapseChapter-${chapter.id}`}
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <ul class="list-group list-group-flush">
                      {content.link.map((link) => (
                        <li class="list-group-item">
                          <a target="_blank" href={link.url}>» {link.label}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <a
                  href={`${process.env.REACT_APP_PLAYBOOK_URI}${chapter.path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Kapitel erneut ansehen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
