import ImagePlaceholder from "../assets/_titelbilder/placeholder.jpg";

import ImageUeberSSVKroschke from "../assets/_titelbilder/ueber-ssv-kroschke.webp";
import ImageKlausKroschkeGruppe from "../assets/_titelbilder/klaus-kroschke-gruppe.webp";
import ImageLEANdustry from "../assets/_titelbilder/leandustry.webp";
import ImageElektrischeAusgabesysteme from "../assets/_titelbilder/elektrisches-ausgabesystem.webp";
import ImageCTeileSortiment from "../assets/_titelbilder/c-teile-sortiment.webp";
import ImageNachhaltigkeit from "../assets/_titelbilder/nachhaltigkeit.webp";
import ImageEProcurement from "../assets/_titelbilder/e-procurement.webp";
import ImageEinsparpotentialAnalyse from "../assets/_titelbilder/einsparpotential-analyse.webp";
import ImagePSAVollversorgung from "../assets/_titelbilder/psa-vollversorgung.webp";
import ImageDienstleistungspaketeFuerBetriebseinrichtung from "../assets/_titelbilder/services-betriebseinrichtung.webp";
import ImageDienstleistungspaketeFuerZerspanung from "../assets/_titelbilder/service-zerspanung.webp";
import ImageVerwaltungMessmittelUndElektrowerkzeuge from "../assets/_titelbilder/verwaltung-messmittel-und-elektrowerkzeuge.webp";
import Referenzen from "../assets/_titelbilder/referenzen.webp";
import Karriere from "../assets/_titelbilder/karriere.webp";

export const imgaes = {
  1: ImageUeberSSVKroschke,
  2: ImageKlausKroschkeGruppe,
  3: ImageLEANdustry,
  4: ImageElektrischeAusgabesysteme,
  5: ImageCTeileSortiment,
  6: ImageNachhaltigkeit,
  7: ImageEProcurement,
  8: ImageEinsparpotentialAnalyse,
  9: ImagePSAVollversorgung,
  10: ImageDienstleistungspaketeFuerBetriebseinrichtung,
  11: ImageDienstleistungspaketeFuerZerspanung,
  12: ImageVerwaltungMessmittelUndElektrowerkzeuge,
  13: Referenzen,
  14: Karriere,
};

export const eReportData = {
  1: {
    headline: "Wir machen unsere Kunden erfolgreicher",
    description:
      "Seit 1993 revolutionieren wir das C-Teile-Management, verschlanken und digitalisieren Beschaffungsprozesse für unsere Kunden und schaffen nachhaltige Wettbewerbsvorteile.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/",
        label: "Mehr zu SSV-Kroschke",
      },
    ],
  },
  2: {
    headline: "Viele Spezialisten unter einem Dach",
    description:
      "Durch den Zusammenschluss mit der Klaus Kroschke Gruppe können wir zahlreiche Kompetenzen bündeln und noch innovativere Lösungen für unsere Kunden finden.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/uber-klaus-kroschke-gruppe",
        label: "Mehr zu Klaus Kroschke Gruppe",
      },
    ],
  },
  3: {
    headline: "Optimale Versorgung in jedem Produktbereich",
    description:
      "Die sinnvolle Zusammenführung von Lean Management und Industrie 4.0 garantiert nachhaltige Einsparpotentiale durch innovative Dienstleistungen und Ausgabesysteme.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv",
        label: (
          <>
            Mehr zu LEANDdustry<sup>®</sup>
          </>
        ),
      },
    ],
  },
  4: {
    headline: "Beschaffungskosten um 30 % senken",
    description:
      "Mit unseren elektronischen Ausgabesystemen bieten wir komplette Lagerkosteneinsparung, logistische Abwicklung rund um die Uhr durch SSV-Kroschke und vieles mehr.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/elektronische-ausgabesysteme",
        label: "Mehr zu elektronischen Ausgabesystemen",
      },
    ],
  },
  5: {
    headline: "Vielfältig und hochwertig",
    description:
      "Das umfangreiche C-Teile-Sortiment bei SSV-Kroschke bietet alles von persönlicher Schutzausrüstung über Zerspanungswerkzeuge bis hin zu Betriebseinrichtungen.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/c-teile-management",
        label: "Mehr zu C-Teile Sortiment",
      },
      {
        url: `${process.env.PUBLIC_URL}/flyer/C7_Flex_Brille_Flyer_210x105mm_ANSICHT.pdf`,
        label: "Flyer C7 Flex Work Brille ",
      },
    ],
  },
  6: {
    headline: "Teil unserer DNA",
    description:
      "Für unsere Kunden schaffen wir nachhaltige Lösungen in der Beschaffung durch Optimierung, Beratung und Ressourcenschonung. Und wir selbst leben es vor bei SSV-Kroschke.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/unternehmen/nachhaltigkeit",
        label: "Mehr zu Nachhaltigkeit",
      },
    ],
  },
  7: {
    headline: "Vereinfachen, automatisieren und optimieren",
    description:
      "Durch Preistransparenz, detaillierte Informationen und Kontrolle über Sortimente, Materialgruppen und Rechnungsprüfung senkt die SSV-Beschaffungsplattform effizient die Einkaufskosten.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/eprocurement",
        label: "Mehr zu E-Procurement",
      },
    ],
  },
  8: {
    headline: "Verbrauchskosten optimieren",
    description:
      "Wir analysieren Beschaffungsprozesse, Produkt- und Lieferantenportfolio sowie interne Logistik- und Lagerkosten. So senken wir mit unseren Dienstleistungspaketen Kosten und steigern die Effizienz.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/einsparpotential-analyse",
        label: "Mehr zu Einsparpotential-Analyse",
      },
    ],
  },
  9: {
    headline: "Geschützt von Kopf bis Fuß",
    description:
      "Von Schuhabwicklung über individuelle Hand- und Hautschutzpläne bis zur Absturzsicherung bieten wir einen professionellen Rundum-Service für Ihre Sicherheit.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/persoenliche-schutzausruestung/psa-vollversorgung",
        label: "Mehr zu PSA-Vollversorgungskonzept",
      },
      {
        url: `/flyer/PSA-Konzept_Flyer_210x105mm_RZ.pdf`,
        label: "Flyer individuelle PSA",
      },
    ],
  },
  10: {
    headline: "Von der Planung bis zur Inspektion",
    description:
      "Unser Dienstleistungspaket für Betriebseinrichtung umfasst alles von der Planung über die Montage bis hin zur Inspektion. Alles aus einer Hand",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/betriebseinrichtung/planung-und-montage",
        label: "Mehr zu Dienstleistungspakete für Betriebseinrichtung",
      },
    ],
  },
  11: {
    headline: "Zerspanung mit System",
    description:
      "Wir bieten eine Komplettversorgung mit Werkzeugen, Spanntechnik und Messmitteln, ein starkes Netzwerk namhafter Partner und individuelle Lösungen.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/zerspanung/anwendungstechnik",
        label: "Mehr zu Dienstleistungspakete für Zerspanung",
      },
    ],
  },
  12: {
    headline: "Effiziente Ressourcenverwaltung",
    description:
      "Messmittel- und Elektrowerkzeugverwaltung mit klaren Zugriffsregelungen, automatischer Kalibrierung und SSV-Mietservice zur flexiblen Senkung der Investitionskosten.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/leandustry-by-ssv/verwaltung-messmittel-und-elektrowerkzeuge",
        label: "Mehr zu Verwaltung Messmittel und Elektrowerkzeuge",
      },
    ],
  },
  13: {
    headline: "Erfolgsgeschichten schreiben",
    description:
      "Wir machen unsere Kunden erfolgreicher und schaffen nachhaltige Wettbewerbsvorteile. Die Stimmen unserer zufriedenen Kunden sprechen für sich.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/referenzen",
        label: "Mehr zu Referenzen",
      },
    ],
  },
  14: {
    headline: "Teil eines innovativen Teams",
    description:
      "Gestalte mit uns deine Zukunft und werde Teil unseres Teams. Starte deine Ausbildung, dein duales Studium oder deine Karriere bei Deutschlands innovativsten C-Teile-Spezialisten.",
    link: [
      {
        url: "https://www.ssv-kroschke.de/karriere",
        label: "Mehr zu Karriere bei SSV-Kroschke",
      },
    ],
  },
};

export const placeholderImage = ImagePlaceholder;
