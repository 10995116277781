import React from "react";
import {
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import BJELogo from "../../assets/img/Logo.png";
import CoverBackground from "../../assets/img/0293_10_Jalousiesteuerung_170608_extra_teppich.jpg";

// const options = {
//   weekday: "long",
//   month: "long",
//   day: "numeric",
//   year: "numeric",
// };
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Interstate",
    paddingTop: 20,
  },
  section: {
    margin: 0,
    padding: 40,
    fontFamily: "Interstate",
  },
  image: {
    width: "30%",
    padding: 10,
  },
  bjeLogoTopRight: {
    width: "20%",
    top: 20,
    right: 15,
    position: "absolute",
  },
  coverImage: {
    padding: 35,
  },
  companyLogo: {
    width: "10%",
    paddingBottom: 3,
  },
  coverBox: {
    backgroundColor: "#E4032E",
    minHeight: 300,
    marginBottom: 50,
    position: "absolute",
    maxWidth: 250,
    minWidth: 250,
    top: 150,
    left: 75,
    padding: 25,
    color: "white",
  },
  coverBoxSubHead: {
    fontSize: 15,
    fontFamily: "Averta",
  },
  coverBoxTitle: {
    paddingTop: 10,
    fontSize: 25,
  },
  coverBoxBJESeperator: {
    borderBottomColor: "white",
    borderBottomWidth: 2.5,
    width: 24,
    paddingTop: 5,
  },
  coverBoxContentText: {
    paddingTop: 25,
    flexShrink: 1,
    maxWidth: 250,
    fontSize: 13,
    lineHeight: "15px",
    fontFamily: "Interstate",
  },
  coverPageContactInfo: {
    flexDirection: "column",
    top: 25,
    padding: 30,
  },
  coverPageSection: {
    padding: 5,
    flexGrow: 1,
    fontFamily: "Interstate",
  },
  coverPageSectionTitle: {
    fontFamily: "Averta",
    color: "#E4032E",
    fontSize: 16,
    paddingBottom: 3,
  },
  padLeft20: {
    paddingLeft: 20,
  },
  padTop: {
    paddingBottom: 10,
  },
  padBottom: {
    paddingBottom: 10,
  },
  marRight: {
    marginRight: 50,
  },
  datesWrapper: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  flexDirectionColumn: {
    flexDirection: "rocolumnw",
  },
  widthLabelColumn: {
    width: "20mm",
  },
  regularTitle: {
    fontFamily: "Averta",
  },
  sectionContent: {
    fontSize: 12,
  },
});

function CoverPage({ customer, adm, appointmentDate, consulting, rating }) {
  const { company } = adm;
  const nextAppointmentDate =
    consulting.next_appointment != null
      ? new Date(consulting.next_appointment).toLocaleDateString("de-DE", {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;
  return (
    <Page size="A4" style={styles.page}>
      <Image style={styles.coverImage} src={CoverBackground} />
      <View style={styles.coverBox}>
        <Text style={styles.coverBoxSubHead}>SSV-Kroschke eReport</Text>
        <Text style={styles.coverBoxTitle}>
          Hallo {customer.firstname} {customer.lastname}
        </Text>
        <View style={styles.coverBoxBJESeperator} />
        <Text style={styles.coverBoxContentText}>
          Hier ist die Zusammenfassung des Treffens mit {adm.firstname}{" "}
          {adm.lastname}, {appointmentDate}.
        </Text>
      </View>
      <View style={styles.coverPageContactInfo}>
        <View style={styles.coverPageSection}>
          <View style={styles.datesWrapper}>
            <View style={styles.flexDirectionRow}>
              <Text style={styles.coverPageSectionTitle}>Termin: </Text>
              <Text> {appointmentDate}</Text>
            </View>
            {consulting.next_appointment !== null &&
              consulting.next_appointment !== "0001-01-01T00:00:00Z" &&
              consulting.next_appointment !== "" && (
                <View style={styles.flexDirectionRow}>
                  <Text style={styles.coverPageSectionTitle}>
                    Nächster Termin:{" "}
                  </Text>
                  <Text> {nextAppointmentDate}</Text>
                </View>
              )}
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <Text style={styles.coverPageSectionTitle}>Ihre Kontaktdaten</Text>
          <View style={styles.sectionContent}>
            <View>
              <Text>
                {customer.salutation} {customer.firstname} {customer.lastname}
              </Text>
              <Text>{customer.email}</Text>
            </View>
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <Text style={styles.coverPageSectionTitle}>Ihr Ansprechpartner</Text>
          <View style={styles.sectionContent}>
            <View>
              {company?.logo && (
                <Image style={styles.companyLogo} src={company.logo} />
              )}
              <Text>
                {adm.salutation} {adm.firstname} {adm.lastname}
              </Text>
              <Text>{adm.email}</Text>
              <Text>Tel: {adm.phonenr}</Text>
              <Text>Fax: {adm.faxnr}</Text>
              {company && (
                <View>
                  <Text>Firma: {company.name}</Text>
                  <Text>Straße: {company.street}</Text>
                  <Text>Ort: {`${company.postcode} ${company.city}`}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <View style={styles.sectionContent}>
            {rating !== undefined && rating !== 0 && (
              <Text>Bewertung: {rating} von 5</Text>
            )}
          </View>
        </View>
      </View>
    </Page>
  );
}

function ChapterPDF({ chapter, fields, fieldValues }) {
  // let hasSeminar = false;
  // let seminarDate;

  // if (
  //   data.seminarAppointment.value !== null &&
  //   data.seminarAppointment.value !== "" &&
  //   data.seminarAppointment.value !== "0001-01-01T00:00:00Z"
  // ) {
  //   hasSeminar = true;
  //   seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
  //     "de-DE",
  //     options
  //   );
  // }

  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>{chapter.name}</Text>
      <View style={styles.sectionContent}>
        {/* <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={chapter} />
          </View>
        </View> */}
        {/* {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{" "}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )} */}
        {fields.map((field) => {
          switch (field.fieldType.dataType) {
            case "string":
              const fieldValue = fieldValues?.find(
                (fieldValue) => fieldValue.field.id === field.id
              );
              if (!fieldValue) {
                return null;
              }
              return (
                <View key={chapter.key}>
                  <View style={styles.flexDirectionRow}>
                    <Text
                      style={[styles.regularTitle, styles.widthLabelColumn]}
                    >
                      {field.description}{" "}
                    </Text>
                    <Text style={styles.marRight}>{fieldValue.value}</Text>
                  </View>
                </View>
              );
            default:
              return null;
          }
        })}
      </View>
    </View>
  );
}

function ExportPDF({ chapters, consultation }) {
  Font.register({
    /*
    family: "Averta",
    src: Averta,
    */
    fontStyle: "normal",
    fontWeight: "normal",
  });
  Font.register({
    fontStyle: "normal",
    fontWeight: "normal",
  });
  // const {
  //   setup: customer,
  //   settings: adm,
  //   consulting,
  //   freeathome,
  //   missiontozero,
  //   welcome,
  //   welcomeip,
  //   knx,
  //   comfortline,
  //   installationsgeraete,
  //   beruehrungslosesschalten,
  //   cwa,
  // } = result;
  // let activeChapters = Object.keys(consulting.activeChapters).filter(
  //   (chapter) => result.consulting.activeChapters[chapter]
  // );
  // const appointmentDate = new Date(customer.selectedDate).toLocaleDateString(
  //   "de-DE",
  //   options
  // );

  const { consulting, consultant, customer, fieldValues } = consultation;

  const options = { weekday: "long", month: "long", day: "numeric" };
  const appointmentDate = new Date(consulting.appointment).toLocaleDateString(
    "de-DE",
    options
  );

  return (
    <Document>
      <CoverPage
        customer={customer}
        adm={consultant}
        consulting={consulting}
        appointmentDate={appointmentDate}
        rating={consulting.rating}
      />
      {chapters.length > 0 && (
        <Page size="A4" style={styles.page}>
          <Image src={BJELogo} style={styles.bjeLogoTopRight} />
          {chapters.map((module) => {
            return (
              <ChapterPDF
                key={module.chapter.key}
                chapter={module.chapter}
                fields={module.fields}
                fieldValues={fieldValues}
              />
            );
          })}
        </Page>
      )}
    </Document>
  );
}

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }
}

// function LoopInfo({ data }) {
//   const renderData = [];
//   Object.entries(data).forEach(([key, entry]) => {
//     //eslint-disable-line array-callback-return
//     if (typeof entry.value === "number") {
//       renderData.push(
//         entry.value > 0 && (
//           <Text>
//             {entry.value}x {entry.description}
//           </Text>
//         )
//       );
//     } else if (typeof entry.value === "boolean") {
//       renderData.push(entry.value && <Text>{entry.description}</Text>);
//     }
//   });

//   return renderData;
// }

export default function DownloadPdf({ chapters, consultation }) {
  const isIpad = isIOS();
  return (
    <div>
      {process.env.NODE_ENV === "development" ? (
        <PDFViewer width="100%" height="1000">
          <ExportPDF chapters={chapters} consultation={consultation} />
        </PDFViewer>
      ) : (
        <PDFDownloadLink
          document={
            <ExportPDF chapters={chapters} consultation={consultation} />
          }
          fileName="export.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              ""
            ) : (
              <a
                href={url}
                rel="noopener noreferrer"
                target={isIpad ? "_self" : "_blank"}
              >
                <button className="q-btn mt-3 q-btn-primary q-small">
                  Als PDF exportieren
                </button>
              </a>
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  );
}
